#root {
  height: 100%;
  max-width: 840px;
  margin: 0 auto;
}

html,
body {
  height: 100%;
  background-color: var(--chakra-colors-blackAlpha-500) !important;
}
